import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

// make cancelled page

const Success = () => {
  const [session, setSession] = useState({});
  // const [error, setError] = useState(null);
  const location = useLocation();
  const sessionId = location.search.replace('?session_id=', '');

  const {
    getAccessTokenSilently,
  } = useAuth0();

//   Get 

  useEffect(() => {
    async function fetchSession() {
        const token = await getAccessTokenSilently({
            audience: 'https://blackthorn.dev/',
            responseType: 'token access_token'
          });
      setSession(
        await fetch('https://blackthorn.dev/checkout-session?sessionId=' + sessionId, {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
                mode: 'no-cors'
            }
        })
        .then((res) =>
          res.json() 
        )
      );
    }
    fetchSession();
    if (session.payment_status === 'paid') {
        console.log(update_reg_status());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, session.payment_status]);

  const update_reg_status = async () => {
    const token = await getAccessTokenSilently({
      audience: 'https://blackthorn.dev/',
      responseType: 'token access_token'
    });

    return fetch('https://blackthorn.dev/api/update_reg_status?reg_update=xy3212', {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        mode: 'no-cors'
      },
      body: JSON.stringify({"reg_update": session.payment_status, "update_type":"auto"})
    }).then(response => response.json())
    .catch(error => console.log(error))
  }

  return (
    <div className="sr-root">
      <div className="sr-main">
        <header className="sr-header">
          <div className="sr-header__logo"></div>
        </header>
        {session.payment_status === 'paid' ?
        <div className="sr-payment-summary completed-view">
          <h4>Your payment succeeded</h4>
          <p>Thank you for confirming your place in AON 2022. We'll be in touch with more details soon.</p>
          <p>We wish you the best of luck with your training.</p>
          <p>Dublin &amp; Wicklow Mountain Rescue Team thanks you for your support.</p>
          <Link to="/">Please click here to view your entries.</Link>
        </div>
        : <div></div>}
        
      </div>

    </div>
  );
};

export default Success;
