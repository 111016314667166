import React, { useState, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Loading from "../components/Loading";
import apiRequest from "../utils/apiRequests";
import { useHistory } from "react-router";

import { 
  Button, 
  Form, 
  FormGroup, 
  Label,
  Input,
  Col,
} from 'reactstrap';

export const Registration = ({ match, location }) => {
  const { params: { verb, id } } = match;

  const {
    getAccessTokenSilently,
    // loginWithPopup,
    // getAccessTokenWithPopup,
  } = useAuth0();

  const { apiOrigin = "https://blackthorn.dev" } = getConfig();

  function RegistrationForm(props) {

    const [formData, setFormData] = useState(
      {"name": '',
      "email": '',
      "phone": '',
      "category": '',
      "date_of_birth": '',
      "sex": '',
      "tshirtsize": '',
      "emergency_name": '',
      "emergency_phone": ''}
    );

    const handleChange = (evt) => {
      setFormData({ ...formData, [evt.target.name]: evt.target.value });
    }

    const history = useHistory();

    useEffect( () => {
      if (verb === "edit") {
        if (!id) {
          return <div>Need an ID</div>
        }
        const getReg = async () => {
          const token = await getAccessTokenSilently({
            audience: 'https://blackthorn.dev/',
            responseType: 'token access_token'
          });
          apiRequest(token, apiOrigin, "get", id)
          .then((response) => {
            console.log(response);
            setFormData(response);
          })
          .catch(error => console.log(error))
        }
        getReg();
      } 
    }, [])


    function handleSubmit(event) {
      event.preventDefault();

      const postReg = async () => {
        const token = await getAccessTokenSilently({
          audience: 'https://blackthorn.dev/',
          responseType: 'token access_token'
        });
        apiRequest(token, apiOrigin, "post", "", formData)
        .then((response) => {
          setFormData(response);
          history.push('/');
        })
        .catch(error => console.log(error))
      }

      const putReg = async () => {
        const token = await getAccessTokenSilently({
          audience: 'https://blackthorn.dev/',
          responseType: 'token access_token'
        });
        apiRequest(token, apiOrigin, "update", id, formData)
        .then((response) => {
          setFormData(response);
          history.push('/');
        })
        .catch(error => console.log(error))
      }


      if (verb === 'create') {
        postReg();
      } else {
        console.log("putReg");
        putReg();
      }
      
    }

    const handleBackClick = (id) => {
      history.push('/');
    }
  
    return (
      <Form onSubmit={handleSubmit}>
        <FormGroup row>
          <Label sm={2} htmlFor="name">Name</Label>
          <Col sm={10}>
          <Input
            name="name"
            type="text"
            value={formData.name}
            onChange={handleChange}
            required
          />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} htmlFor="email">Email</Label>
          <Col sm={10}>
          <Input
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} htmlFor="phone">Phone</Label>
          <Col sm={10}>
          <Input
            name="phone"
            type="phone"
            value={formData.phone}
            onChange={handleChange}
            required
          />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} htmlFor="category">Category</Label>
          <Col sm={10}>
          <Input
            type="select"
            name="category"
            value={formData.category}
            onChange={handleChange}
            required
          >
            <option value="">Please choose</option>
            <option value="trek">Trek</option>
            <option value="hybrid">Hybrid</option>
            <option value="ultra">Ultra</option>
        </Input>
        </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} htmlFor="date_of_birth">Date of birth</Label>
          <Col sm={10}>
          <Input
            name="date_of_birth"
            type="date"
            value={formData.date_of_birth}
            onChange={handleChange}
            required
          />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} htmlFor="age">Sex</Label>
          <Col sm={10}>
          <Input
            type="select"
            name="sex"
            value={formData.sex}
            onChange={handleChange}
            required
          >
            <option value="">Please choose</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </Input>
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label sm={2} htmlFor="tshirtsize">T-shirt size</Label>
          <Col sm={10}>
          <Input
            type="select"
            name="tshirtsize"
            value={formData.tshirtsize}
            onChange={handleChange}
            required
          >
           <option value="">Please choose</option>
           <option value="small">Small</option>
           <option value="medium">Medium</option>
           <option value="large">Large</option>
           <option value="xlarge">Extra large</option>
          </Input> 
          </Col>
        </FormGroup>
        <FormGroup tag="fieldset">
          <legend>Emergency contact</legend>
          <FormGroup row>
            <Label sm={2} htmlFor="emergency-name">Name</Label>
            <Col sm={10}>
            <Input
              name="emergency_name"
              type="text"
              value={formData.emergency_name}
              onChange={handleChange}
              required
            />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={2} htmlFor="emergency-phone">Phone</Label>
            <Col sm={10}>
            <Input
              name="emergency_phone"
              type="phone"
              value={formData.emergency_phone}
              onChange={handleChange}
              required
            />
            </Col>
          </FormGroup>
        </FormGroup>
        <Button color="success" type="submit">Submit</Button>
        {' '}
        <Button color="secondary" outline onClick={() => handleBackClick(id)}>Go back</Button>
      </Form>
    );
  }
  return (
    <RegistrationForm verb={verb} id={id} />
  );

};

export default withAuthenticationRequired(Registration, {
  onRedirecting: () => <Loading />,
});
