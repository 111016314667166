import React, { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { 
    Form,
    Input,
    Button
  } from 'reactstrap';

const formatPrice = ({ amount, currency, quantity }) => {
  const numberFormat = new Intl.NumberFormat('en-IE', {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
  });
  const parts = numberFormat.formatToParts(amount);
  let zeroDecimalCurrency = true;
  for (let part of parts) {
    if (part.type === 'decimal') {
      zeroDecimalCurrency = false;
    }
  }
  amount = zeroDecimalCurrency ? amount : amount / 100;
  const total = (quantity * amount).toFixed(2);
  return numberFormat.format(total);
};

const Checkout = (props) => {
  const {
    getAccessTokenSilently,
  } = useAuth0();
  
  const [quantity, setQuantity] = useState(0);
  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState('EUR');

  useEffect(() => {
    async function fetchConfig() {
        const token = await getAccessTokenSilently({
            audience: 'https://blackthorn.dev/',
            responseType: 'token access_token'
          });
      // Fetch config from our backend.
      const {
        unitAmount,
        currency
      } = await fetch('https://blackthorn.dev/config', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          mode: 'no-cors'
        }
      })
      .then(r => r.json());
      setAmount(unitAmount);
      setCurrency(currency);
      setQuantity(props.reg_count);
      }
    fetchConfig();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  
  function handleChange(event) {
      setQuantity(event.target.value);
  }

  return (
    
    <div>
        {quantity === null ? 
        <div></div>
        :
          <div>
        <section>
          <div>
            <h4>Congratulations! You've been offered a place in AON 2022.</h4>
            <p>Please click on the button below to pay the entry fee and confirm your place.</p>
            <Form action="https://blackthorn.dev/create-checkout-session" method="POST">
                {/* onSubmit={handleSubmit}  */}
                <Input
                    type="hidden"
                    value={quantity}
                    name="quantity"
                    onChange={handleChange}
                />
                <Button color="success" type="submit">
                    Pay {formatPrice({amount, currency, quantity})}
                </Button>
            </Form>
          </div>
            
        </section>
      </div>
      }
    </div>
  );
};

export default Checkout;
