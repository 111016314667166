const resolveFetchMethod = (method) => {
  switch (method) {
    case "get":
      return 'GET'
    case "post":
      return 'POST'
    case "update":
      return 'PUT'
    case "delete":
      return 'DELETE'
    default:
      return 'GET'
  }
}

async function makeApiRequest(token, apiOrigin, method, id = "") {
  const regId = id;
  const fetchMethod = resolveFetchMethod(method);

  return fetch(`${apiOrigin}/api/registration/${regId}`, {
    method: fetchMethod,
    headers: {
      Authorization: `Bearer ${token}`,
      mode: 'no-cors'
    }
  }).then(response => response.json())
}

async function makeApiPayloadRequest(token, apiOrigin, method, id = "", payload ={}) {
  const regId = id;
  const fetchMethod = resolveFetchMethod(method);

  return fetch(`${apiOrigin}/api/registration/${regId}`, {
    method: fetchMethod,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      mode: 'no-cors'
    },
    body: JSON.stringify(payload)
  }).then(response => response.json())
}

const apiRequest = async (token, apiOrigin, method, id = "", payload = {}) => {
  if (method === "get" || method === "delete") {
    return makeApiRequest(token, apiOrigin, method, id);
  } else {
    return makeApiPayloadRequest(token, apiOrigin, method, id, payload);
  }
}

export default apiRequest;