import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
// import history from "./utils/history";
// import { getConfig } from "./config";

// const onRedirectCallback = (appState) => {
//   history.push(
//     appState && appState.returnTo ? appState.returnTo : window.location.pathname
//   );
// };

// Please see https://auth0.github.io/auth0-react/interfaces/auth0_provider.auth0provideroptions.html
// for a full list of the available properties on the provider
// const config = getConfig();

// const providerConfig = {
//   domain: config.domain,
//   clientId: config.clientId,
//   ...(config.audience ? { audience: config.audience } : null),
//   redirectUri: window.location.origin,
//   onRedirectCallback,
// };

ReactDOM.render(
  <Auth0Provider
    domain="aon-dev.eu.auth0.com"
    clientId="3be3yvKMltCncRg6NfsiTbYKkLSkgiVQ"
    redirectUri={window.location.origin}
    audience="https://blackthorn.dev/"
  >
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
