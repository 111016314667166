import React from 'react';
import { Link } from 'react-router-dom';

// make cancelled page

const Cancelled = () => {

  return (
    <div className="sr-root">
      <div className="sr-main">
        <header className="sr-header">
          <div className="sr-header__logo"></div>
        </header>
        <div className="sr-payment-summary completed-view">
          <h4>Your payment was cancelled.</h4>
          <p>Your card was not charged, and you have not confirmed your place.</p>
        </div>
        <Link to="/">Please click here to view your entries.</Link>
      </div>

    </div>
  );
};

export default Cancelled;
