import React, { useState, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import { getConfig } from "../config";
import apiRequest from "../utils/apiRequests";
import { useHistory } from "react-router";

import { 
  Button,
  Card, 
  CardBody,
  CardTitle,
  CardText,
  Row,
  Col
} from 'reactstrap';
import Checkout from "../components/Checkout";

export const ListRegComponent = () => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [regs, setRegs] = useState([]);
  const [showDiv, setShowDiv] = useState(false);
  const [expandedReg, setExpandedReg] = useState(0);
  // const [regCount, setRegCount] = useState(0);
  const [serverMessage, setServerMessage] = useState('');
  const [placeOffer, setPlaceOffer] = useState(false);

  const history = useHistory();

  const getReg = async () => {
    const token = await getAccessTokenSilently({
      audience: 'https://blackthorn.dev/',
      responseType: 'token access_token'
    });
    apiRequest(token, apiOrigin, "get")
    .then((response) => {
      setRegs(response.regs);
      // setRegCount(response.length);
      setPlaceOffer(response.regstatus);
      setIsLoaded(true);
    })
    .catch(error => setError(error))
  }

  useEffect( () => {
        getReg();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    getAccessTokenSilently,
    // loginWithPopup,
    // getAccessTokenWithPopup,
  } = useAuth0();

  const { apiOrigin = "https://blackthorn.dev" } = getConfig();

  const deleteReg = async (id) => {
    const token = await getAccessTokenSilently({
      audience: 'https://blackthorn.dev/',
      responseType: 'token access_token'
    });
    console.log("Delete: " + id);
    apiRequest(token, apiOrigin, "delete", id)
    .then((response) => {
      setServerMessage(response.status);
      setTimeout(() => setServerMessage(''), 3000);
      getReg();
    })
    .catch(error => setError(error))
  }

  const showHideReg = (id) => {
      setShowDiv(!showDiv);
      if (expandedReg === id) {
        setExpandedReg(0);
      } else {
        setExpandedReg(id);
      }        
  }

  const handleEditClick = (id) => {
    history.push(`registration/edit/${id}`)
  }

  // const handleAddRegClick = () => {
  //   history.push('registration/create')
  // }

  if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div>
          {regs.length > 0 && placeOffer === 'offer' ?
          <Checkout reg_count={regs.length} />
          : <div className="nooffer"></div>}
          {/* <div>
            {regCount < 4 ? 
            <Button color="success" size="lg" onClick={handleAddRegClick}>Add new registration</Button>
            : <p>You've reached the maximum number of entries permitted in your group.</p>}
          </div> */}
          <div>
            <p>{serverMessage !== '' ? serverMessage : ""}</p>
          </div>
          {regs.length > 0 ?
          regs.map(reg => (
            
            <div key={reg.id}>
              <Card color="secondary" outline>
                <CardBody>
                  <CardTitle tag="h5">{reg.name}</CardTitle>
                  {expandedReg === reg.id ? 
                    <div>
                      <Row xs="2">
                          <Col xs="4">Phone: </Col>
                          <Col xs="8">{reg.phone}</Col>
                      </Row>
                      <Row xs="2">
                          <Col xs="4">Email: </Col>
                          <Col xs="8">{reg.email}</Col>
                      </Row>
                      <Row xs="2">
                          <Col xs="4">DoB: </Col>
                          <Col xs="8">{reg.date_of_birth}</Col>
                      </Row>
                      <Row>
                          <Col xs="4">Sex: </Col>
                          <Col xs="8">{reg.sex}</Col>
                      </Row>
                      <Row>
                          <Col xs="4">Category: </Col>
                          <Col xs="8">{reg.category}</Col>
                      </Row>
                      <Row>
                          <Col xs="4">T-shirt: </Col>
                          <Col xs="8">{reg.tshirtsize}</Col>
                      </Row>
                      <Row>
                        <Col xs="4">
                          <h6>Emergency contact</h6>
                        </Col>
                      </Row>
                      <Row>
                          <Col xs="4">Name: </Col>
                          <Col xs="8">{reg.emergency_name}</Col>
                      </Row>
                      <Row>
                          <Col xs="4">Phone: </Col>
                          <Col xs="8">{reg.emergency_phone}</Col>
                      </Row>
                    </div>
                  : <CardText></CardText>}
                  <Button color="info" size="sm" onClick={() => showHideReg(reg.id)}>{expandedReg === reg.id ? "Show less" : "Show more"}</Button>
                  {' '}
                  <Button color="success" size="sm" onClick={() => handleEditClick(reg.id)}>Edit</Button>
                  {' '}
                  <Button color="danger" size="sm" outline onClick={() => deleteReg(reg.id)}>Delete</Button>
                </CardBody>
              </Card>
            </div>
            
          )) : <div>You have no registrations yet. Please click the button to add one.</div>}
        </div>
      );
    }
};

export default withAuthenticationRequired(ListRegComponent, {
    onRedirecting: () => <Loading />,
  });


